<template>
  <div>
    LOGIN
  </div>
</template>

<script>
  export default {
    title: '登陆',
    meta: {name: '用户登陆',noRoute: true}
  }
</script>

<style lang="scss" scoped>

</style>