import Vue from "vue";
import VueRouter from "vue-router";
import util from "@/utils";
import config from "@/config";

Vue.use(VueRouter);

let fileArr = util.auto.fileAutomate(require.context("@/views", true, /.vue$/));

let pages = [];

fileArr.forEach((item) => {
  let path = "/" + item.path.replace(/(.*)\/index.vue/, "$1");
  pages.push({
    path: item.content.path || path,
    name: item.content.meta.name || path,
    meta: { ...item.content.meta, name: item.content.title },
    component: item.content,
  });
});

const routes = [
  {
    path: "/",
    name: "layout",
    component: () => import("@/layouts/index.vue"),
    redirect: "/home",
    children: [
      ...pages,
      {
        path: "404",
        name: "404",
        component: () => import("@/views/404/index.vue"),
      },
    ],
  },
  // {
  //   path: "/",
  //   name: "layout",
  //   component: () => import("@/layouts/index.vue"),
  //   children: [
  //     {
  //       path: "*",
  //       name: "404",
  //       component: () => import("@/views/404/index.vue"),
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.name) document.title = config.WEB_NAME + "-" + to.meta.name;
  next();
});

export default router;
