<template>
  <div>
    USER
  </div>
</template>

<script>
  export default {
    title: '表单',
    meta: {name: '表单'}
  }
</script>

<style lang="sass" scoped>

</style>