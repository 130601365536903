import request from "@/utils/request";

/**
 * 请求周易信息
 * @param pram
 */
export function zhouYiApi(params) {
  return request({
    url: "/zhouyi",
    method: "get",
    params,
  });
}

/**
 * post请求
 * @param pram
 */
// export function couponSendApi(data) {
//   return request({
//     url: "/admin/platform/coupon/batch/send",
//     method: "post",
//     data,
//   });
// }
