<template>
  <div class="user">
    <div class="user_modue head">
      <div class="avatar">
        <img src="http://8.134.151.130/image/dd.jpg" alt="">
      </div>
      <div class="userinfo">
        <div class="nickname">飞天大炮</div>
        <div class="account_id">ID: 10013</div>
        <div class="remark">为什么每天不能睡25个小时啊。</div>

        <div class="data">
          <div class="data_item">
            <div class="num"></div>
            <div class="title"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: '用户',
  meta: { name: 'user', submenuTitle: '用户', submenuIcon: 'el-icon-s-custom' },
  data() {
    return {
      data: {
        honors: [],
        srcList: []
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.user {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to left, #ffdc99 20%, #ff62c0 80%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user_modue {
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 10px 10px 4px rgba(240, 240, 240, 0.7);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.head {
  margin-top: 100px;

  .userinfo {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .nickname {
      font-weight: 600;
      font-size: 20px;
    }

    .account_id {
      margin-top: 10px;
      font-weight: 600;
    }
  }
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-top: -50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

@media (max-width: 1200px) {}

// 平板端
@media (max-width: 992px) {}

// 手机端
@media (max-width:768px) {
  .user_modue {
    width: 90%;
  }
}
</style>