<template>
    <el-color-picker v-model="theme" :predefine="predefine" class="theme-picker" popper-class="theme-picker-dropdown" />
</template>

<script>
import themeMixin from '@/mixin/updateTheme'

export default {
    name: 'ThemePicker',
    mixins: [themeMixin],
    data() {
        return {
            theme: '',
            onTheme: '',
            predefine: ['#256DCB', '#409EFF', '#1890ff', '#304156', '#212121', '#11a983', '#13c2c2', '#6959CD', '#F5222D', '#F80'],
        }
    },
    async created() {
        const val = this.$util.localStorage.getItem(this.$store.state.settings.key) || "#256DCB"
        this.$store.dispatch('settings/changeSetting', {
            key: 'theme',
            value: val
        })
        this.updateTheme(val)
    },
    computed: {
        defaultTheme() {
            return this.$store.state.settings.theme
        }
    },
    watch: {
        defaultTheme: {
            handler: function (val, oldVal) {
                this.theme = val
            },
            immediate: true
        },
        async theme(val, onVal) {
            if (!val) this.theme = '#256DCB'
            this.onTheme = onVal || '#256DCB'
            this.$store.dispatch('settings/changeSetting', {
                key: 'theme',
                value: val || '#256DCB'
            })
            this.updateTheme(val || '#256DCB', true)
        }
    }
}
</script>