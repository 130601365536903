import util from "@/utils";
export default {
  namespaced: true,
  state: {
    router: [],
  },
  getters: {},
  mutations: {
    setRouter(state, payload) {
      state.router = payload;
    },
  },
  actions: {
    actSetRouter({ commit }) {
      let fileArr = util.auto.fileAutomate(
        require.context("@/views", true, /.vue$/)
      );

      let pages = [];

      fileArr.forEach((item) => {
        if (item.content.meta.noRoute) return; // 剔除功能页面
        let path = item.path.replace(/(.*)\/index.vue/, "$1");
        let pathArr = path.split("/");
        if (pathArr.length > 1) {
          // console.log("子路由", pathArr);
          // 子路由
          let index = pages.findIndex(
            (i) => i.path.split("/").pop() == pathArr[0]
          );
          if (index != -1) {
            // 已经存在
            pages[index].children
              ? pages[index].children.push({
                  name: item.content.title || item.content.meta.name,
                  path: item.content.path || "/" + path,
                })
              : (pages[index].children = [
                  {
                    name: pages[index].name,
                    path: pages[index].path,
                    icon: pages[index].icon || "",
                  },
                  {
                    name: item.content.title || item.content.meta.name,
                    path: item.content.path || "/" + path,
                    icon: item.content.meta.icon || "",
                  },
                ]);
          } else {
            // 暂无数据
            pages.push({
              name: item.content.title || item.content.meta.name,
              path: item.content.path || "/" + path,
              icon: item.content.meta.icon || "",
            });
          }
        } else {
          // console.log("主路由", pathArr);
          // 主路由
          pages.push({
            name: item.content.title || item.content.meta.name,
            path: item.content.path || "/" + path,
            icon: item.content.meta.icon || "",
            submenuIcon: item.content.meta.submenuIcon || "",
            submenuTitle: item.content.meta.submenuTitle,
          });
        }
      });
      commit("setRouter", pages);
      // console.log(pages);
    },
  },
};
