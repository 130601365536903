<template>
  <div class="err">
    待开发...
  </div>
</template>

<script>
export default {
  title: '404',
  meta: { name: '404', submenuTitle: '404', noRoute: true },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.err {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  font-weight: 600;
  background: -webkit-linear-gradient(315deg, #42d392 25%, #647eff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>