export default {
  namespaced: true,
  state: {
    skin: "white",
    skinList: [
      {
        type: "white",
        style: {
          "--bg_color": "#282c34",
        },
      },
      {
        type: "black",
        style: {
          "--bg_color": "#000",
        },
      },
    ],
  },
  getters: {},
  mutations: {
    setSkin(state, payload) {
      state.skin = payload;
    },
  },
  actions: {},
};
