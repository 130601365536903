<template>
  <div class="home">
    <div class="home_content">
      <img class="head" @click="setSaying" src="@/assets/image/home/home.gif" alt="" />
      <!-- <div class="saying color">
        <p class="text">{{ sayingText }}</p>
      </div> -->
      <div class="content">
        <div class="saying color">
          <p class="text">{{ sayingText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "./components/banner.vue";
var sayingTime = null;
export default {
  components: { banner },
  title: "首页",
  meta: { name: "home", submenuTitle: "首页", icon: "el-icon-s-home" },
  data() {
    return {
      swiper: [{ image: this.$config.IMAGE_URL + "dd.jpg" }],
      saying: [
        "持续温柔，持续快乐。",
        "开心的东西要专心的记着。",
        "快乐生活，知足常乐，享受美好。",
        "想成为一个永远热爱生活，永远期待明天的普通人。",
        "清晨有光，我在路上，向远方。",
        "快乐出现的时候，就好好享受快乐。",
        "接受自己的普通然后全力以赴的出众。",
        "心情和天气，同步升温。",
        "所行皆所获，向阳皆不凡。",
        "愿快乐不止今天，永久有效！",
        "要赚很多钱去买我的快乐。",
        "快乐与平凡，最浪漫。",
        "我的花会开，我的生活也会慢慢拥抱我。",
        "因为有了黑暗，星星才会如此闪耀。",
        "经历过至暗时刻，才能成为一束光。",
        "日子大多数都很平常，有时甚至不如意，但总有一点点小美好和小欢喜，值得我们珍藏。",
        "好好热爱生活吧。",
        "生活的温柔总会哒哒哒的跑进你的怀里。",
        "今日无事，唯有开心。",
        "日子，一路向阳！",
        "一些小美好正在井然有序地发生着。",
        "心有一隅，房子大的烦恼就只能挤在一隅中, 心有四方天地，山大的烦恼也不过是沧海一粟。",
        "甩甩头，把烦恼丢去，仰起笑脸，与这世间所有美好相逢。",
        "我们的背包已装满晴朗，出发去山顶晒月光。",
        "梦醒人间看微雨，江山还似就温柔。",
        "万家灯火与群星，人间值得的又一刻。",
        "心情就像衣服，脏了拿去洗洗，晒晒，阳光自然就会蔓延开来。",
        "生而自由，爱而无畏。",
        "心藏漂亮，眼带光芒，生活是自己喜欢的模样。",
        "今天的空气，掺了份甜。",
        "在喜欢你的人那里，去热爱生活。",
        "在不喜欢你的人那里，去看清世界。",
        "就这么简单。",
        "生活的理想，就是为了理想的生活。",
        "你说，你热爱生活。我想是的。",
        "星辰大海都揉进你眼中，我看到了整个世界该有的温柔。",
        "开开心心，不动脑筋。",
        "湛蓝天空上像奶油泡芙一样的云朵溢出冰淇淋的味道。",
        "把自己活成一道光，潇洒且硬气的穿行在这个世界上。",
        "遇上有趣的人和生活。",
        "羡慕别人的天空简直没有道理，因为你是一座宇宙。",
        "我生来平庸，也生来骄傲。",
        "抬头看看天空，低头看看花朵，生活中许多美好的事情，让我们不断感受这世间莫大的善意。",
        "美丽心情，营业中。",
        "不争，自然从容；不计较，所以长快乐。",
        "旁边是熙熙攘攘的人流，身后是明明暗暗的灯火，沿河十里，从古亮到今长长久久。",
        "对待自己温柔一点，你只不过是宇宙的孩子，与植物、星辰没什么两样。",
        "也许好运正在排队，等着见我，它也很焦急。",
        "日子温柔，从头到脚都快乐！",
        "太阳下山了夜里也有灯打开，你看，这世界不坏。",
        "静静地生活，不冷不热，不慌不忙。",
        "人生明朗又轻快，开心又自在。",
        "海与浪，温与柔，幸与不幸都会有尽头。",
        "好心情每天照常营业。",
        "试试看每天吃一颗糖，然后告诉自己，今天的日子果然又是甜的。",
      ],
      sayingText: "",
    };
  },
  mounted() {
    setTimeout(() => this.setSaying(), 1000)
  },
  methods: {
    // 设置祝福语
    setSaying() {
      clearInterval(sayingTime);
      let index = Math.floor(Math.random() * this.saying.length);
      let text = this.saying[index];
      let textLength = 0;
      this.sayingText = "";
      sayingTime = setInterval(() => {
        if (text.length - 1 < this.sayingText.length)
          return clearInterval(sayingTime);
        this.sayingText = this.sayingText + text[textLength];
        textLength++
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to left, #ffdc99 20%, #ff62c0 80%);
  display: flex;
  justify-content: center;
  align-items: center;

  .home_content {
    width: 750px;
    text-align: center;

    .head {
      width: 30%;
    }

    .saying {
      margin-bottom: 20px;
      color: var(--color);
      padding: 0 20px;

      .text::after {
        content: "|";
        font-weight: 600;
        animation: shan 1s infinite linear;
        opacity: 1;
      }

      @keyframes shan {
        50% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    .content {
      width: 100%;
      height: 100px;
      background: rgba(255, 255, 255, 0.4);
      box-shadow: 10px 10px 4px rgba(240, 240, 240, 0.7);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 750px) {
  .home .home_content {
    width: 90%;
  }
}
</style>
