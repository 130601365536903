import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import utils from "./utils";
import config from "./config";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/styles/element-variables.scss";
import "./assets/styles/stylesheet.css";
import '@/components/index'

Vue.config.productionTip = false;

Vue.prototype.$util = utils;
Vue.prototype.$config = config;
Vue.prototype.$store = store;

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
