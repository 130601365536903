<template>
  <div id="app">
    <router-view />
    <div class="ufo" ref="ufo" @click="mouseenter"><my-ufo /></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  computed: {
    ...mapState({
      skin: state => state.app.skin
    })
  },
  methods: {
    ...mapActions({
      actSetRouter: 'router/actSetRouter',
    }),
    mouseenter() {
      let windosWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let windosHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      let dom = {
        width: 80,
        height: 133
      }
      let maxWidth = windosWidth - dom.width
      let maxHeight = windosHeight - dom.height
      let newWidth = Math.floor(Math.random() * windosWidth)
      let newHeight = Math.floor(Math.random() * windosHeight)
      this.$refs.ufo.style.left = (newWidth > maxWidth ? maxWidth : newWidth) + 'px'
      this.$refs.ufo.style.top = (newHeight > maxHeight ? maxHeight : newHeight) + 'px'
    },
  },
  mounted() {
    this.actSetRouter()
    document.querySelector('body').classList.add('color')
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

// 滚动条宽度
.color::-webkit-scrollbar {
  width: 6px;
}

// 滚动条轨道
.color::-webkit-scrollbar-track {
  // background: rgb(239, 239, 239);
  border-radius: 2px;
}

// 小滑块
.color::-webkit-scrollbar-thumb {
  background: var(--color);
  border-radius: 10px;
}

.ufo {
  position: absolute;
  z-index: 99999;
  top: 0px;
  left: 0px;
  transition: 1s;
}
</style>
