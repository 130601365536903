/**
 * @function fileAutomate 前端工程自动化-文件处理函数
 * @param file {any} 文件
 * @param eliminate {array} 需要排除的文件
 */

function fileAutomate(file,eliminate = ['components']) {
  if (!file) return new Error("参数为空或者参数类型错误");
  let fileArr = [];
  file.keys().forEach((path) => {
    let content = file(path).default; // 获取文件 export default 内容
    // console.log(path,path.replace(/.\/(.*?)/,'$1').split('/'),!path.replace(/.\/(.*?)/,'$1').split('/').some(i=>eliminate.includes(i)));
    if(!path.replace(/.\/(.*?)/,'$1').split('/').some(i=>eliminate.includes(i))) fileArr.push({ path: path.replace(/.\/(.*?)/,'$1'), content });
  });
  return fileArr;
}

export default {
  fileAutomate,
};
