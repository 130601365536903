<template>
    <div>
        SETTING
    </div>
  </template>
  
  <script>
    export default {
      title: '用户设置',
      meta: {name: '用户设置', icon: 'el-icon-s-claim'}
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>