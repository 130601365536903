<template>
    <div class="set_skin" :style="{ zIndex: isCollapse ? '99999' : '0' }">
        <div class="mask" @click="setCollapse" v-if="isCollapse"></div>
        <div class="skin_content"
            :style="{ right: isCollapse ? '40px' : '0', transform: `translate(${isCollapse ? '0' : '100%'}, 0)` }">

            <div class="color_list">
                <div class="color_btn" v-for="item, index in predefine" :key="index">
                    <div class="color"
                        :style="{ background: item, '--bg': item, '--show': item == theme ? 'block' : 'none' }"
                        @click="setColor(item)"></div>
                </div>
            </div>
            <!-- <ThemePicker /> -->
            <!-- <div class="skin_btn" @click="setCollapse"><i class="el-icon-s-unfold"></i></div> -->
            <el-button class="skin_btn" @click="setCollapse" type="primary" icon="el-icon-s-unfold" circle></el-button>
        </div>
    </div>
</template>

<script>
import themeMixin from '@/mixin/updateTheme'
export default {
    mixins: [themeMixin],
    data() {
        return {
            theme: '',
            onTheme: '',
            isCollapse: false,
            predefine: ['#256DCB', '#409EFF', '#1890ff', '#304156', '#212121', '#11a983', '#13c2c2', '#6959CD', '#F5222D', '#FF8800']
        }
    },
    async created() {
        const val = this.$util.localStorage.getItem(this.$store.state.settings.key) || "#256DCB"
        this.$store.dispatch('settings/changeSetting', {
            key: 'theme',
            value: val
        })
        this.updateTheme(val)
    },
    computed: {
        defaultTheme() {
            return this.$store.state.settings.theme
        }
    },
    watch: {
        defaultTheme: {
            handler: function (val, oldVal) {
                this.theme = val
            },
            immediate: true
        },
        async theme(val, onVal) {
            if (!val) this.theme = '#256DCB'
            this.onTheme = onVal || '#256DCB'
            this.$store.dispatch('settings/changeSetting', {
                key: 'theme',
                value: val || '#256DCB'
            })
            this.updateTheme(val || '#256DCB', true)
        }
    },
    methods: {
        setCollapse() {
            this.isCollapse = !this.isCollapse
        },
        setColor(val) {
            this.theme = val
        }
    }
}
</script>

<style lang="scss" scoped>
.set_skin {
    position: fixed;
    top: 0;
    left: 0;

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99999;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .5);
        cursor: pointer;
    }

    .skin_content {
        position: fixed;
        z-index: 100000;
        width: 260px;
        background: #fff;
        transition: .5s;
        top: 0;
        right: 40px;
        margin-top: 20px;
        border-radius: 6px;
        height: calc(100% - 40px);

        .color_list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            margin-top: 20px;

            .color_btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .color {
                    width: 50px;
                    height: 50px;
                    border-radius: 6px;
                    position: relative;

                    &::after {
                        content: '';
                        display: var(--show);
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        z-index: 1;
                        transform: translate(-50%, -50%);
                        border: 1px solid var(--bg);
                        width: calc(100% + 4px);
                        height: calc(100% + 4px);
                        border-radius: 6px;
                        box-shadow: 0 0 4px var(--bg);
                    }
                }
            }
        }



        .skin_btn {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 100000;
            transform: translate(-100%, -50%);
            // background: #fff;
            width: 50px;
            height: 50px;
            border-radius: 50% 0 50% 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 0 0 2px rgba(0, 0, 0, .2);

            i {
                font-size: 24px;
            }
        }
    }
}
</style>