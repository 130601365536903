<template>
    <div>
        <!-- style="z-index: 20000; display: block; position: fixed; top: -10px; right: 540px; width: 80px; transform: translate(20px, 475px); opacity: 1;" -->
        <svg id="ufo" style="width: 80px" viewBox="75 200 150 250" xmlns="http://www.w3.org/2000/svg" class="ufo"
            data-svg-origin="82.69871520996094 213.99908447265625" transform="matrix(1,0,0,1,0,5.942202423338026)">
            <defs>
                <linearGradient id="beamGrad" x1="150" y1="442" x2="150" y2="323" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-opacity="0"></stop>
                    <stop offset="1" stop-color="#fcef29"></stop>
                </linearGradient>
            </defs>

            <g class="ringContainer">
                <ellipse class="ring" cx="150" cy="260" rx="50" ry="10" fill="none" stroke-width="2"
                    stroke="var(--border-header, #5BC0EB)" opacity="0.4"></ellipse>
                <ellipse class="ring" cx="150" cy="260" rx="50" ry="10" fill="none" stroke-width="2"
                    stroke="var(--border-header, #5BC0EB)" opacity="0.4">
                    <animateTransform attributeName="transform" attributeType="XML" type="translate" values="0 80;0 150"
                        dur="500ms" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="rx" values="16;50" dur="500ms" repeatCount="indefinite"></animate>
                    <animate attributeName="ry" values="4;10" dur="500ms" repeatCount="indefinite"></animate>
                </ellipse>
            </g>

            <g class="ufoGroup" data-svg-origin="149.99999237060547 213.99908447265625" transform="matrix(1,0,0,1,0,0)"
                style="z-index: 0; transform-origin: 0px 0px 0px;">
                <polygon class="beam" points="133 323 103 442 197 442 167 323 133 323" fill="url(#beamGrad)"
                    opacity="0.3" data-svg-origin="150 323" transform="matrix(1,0,0,1,0,0)"
                    style="z-index: 0; transform-origin: 0px 0px 0px;"></polygon>
                <g class="aerialGroup" data-svg-origin="149.9999771118164 240" transform="matrix(1,0,0,1,0,0)"
                    style="z-index: 0; transform-origin: 0px 0px 0px;">
                    <path class="aerialStalk"
                        d="M148.16,240V224.6a1.9,1.9,0,0,1,1.53-1.9,1.84,1.84,0,0,1,2.15,1.81V240Z" fill="#666"></path>
                    <g>
                        <path d="M156.55,220.52A6.55,6.55,0,1,1,150,214,6.55,6.55,0,0,1,156.55,220.52Z"
                            fill="var(--border-header, #f73e3e)"></path>
                        <path
                            d="M147.16,220.52a6.55,6.55,0,0,1,4.7-6.29A6.74,6.74,0,0,0,150,214a6.56,6.56,0,0,0,0,13.11,6.74,6.74,0,0,0,1.86-.27A6.55,6.55,0,0,1,147.16,220.52Z"
                            fill="#231f20" opacity="0.1"></path>
                    </g>
                    <circle class="beep" cx="150" cy="220" r="10" fill="none" stroke-width="1"
                        stroke="var(--border-header, #D7263D)" opacity="0.31">
                        <animate attributeName="r" values="2;30" dur="600ms" repeatCount="indefinite"></animate>
                        <animate attributeName="stroke-width" values="10;2" dur="600ms" repeatCount="indefinite">
                        </animate>
                    </circle>
                </g>
                <path class="ufoBase"
                    d="M119.58,308.34l-5.46,7.36a1.76,1.76,0,0,0,1.42,2.81h68.89a1.76,1.76,0,0,0,1.4-2.82l-5.55-7.36a1.79,1.79,0,0,0-1.41-.7H121A1.77,1.77,0,0,0,119.58,308.34Z"
                    fill="var(--border-header-hover, #304649)" stroke="#666" stroke-miterlimit="10" stroke-width="2">
                </path>
                <g class="lid">
                    <path
                        d="M112.21,279.06h75.58a3.81,3.81,0,0,0,3.76-4.49,42.25,42.25,0,0,0-41.48-34.66,42.15,42.15,0,0,0-29.82,12.4,42.66,42.66,0,0,0-11.8,22.26A3.81,3.81,0,0,0,112.21,279.06Z"
                        fill="var(--border-header, #61ccf2)" fill-opacity="0.2" stroke="#666" stroke-miterlimit="10"
                        stroke-width="2"></path>
                    <ellipse cx="166.07" cy="253.42" rx="4.53" ry="6.94"
                        transform="translate(-133.25 206.44) rotate(-47.84)" fill="var(--border-header, #67daf9)"
                        fill-opacity="0.3"></ellipse>
                </g>
                <g class="ufoBody">
                    <path
                        d="M91.48,296.34H214.37a2.84,2.84,0,0,0,2.09-.83h0a2.84,2.84,0,0,0,.84-2.09,2.81,2.81,0,0,0-.84-2.09l-.14-.14a2.81,2.81,0,0,0-2.09-.84H85.63a2.81,2.81,0,0,0-2.09.84h0a2.81,2.81,0,0,0-.84,2.09,2.84,2.84,0,0,0,.84,2.09l.14.14a2.84,2.84,0,0,0,2.09.83Z"
                        fill="var(--border-header, #567C81)" stroke="#666" stroke-miterlimit="10" stroke-width="2">
                    </path>
                    <path
                        d="M88.83,290.35H211.17a20.56,20.56,0,0,0-5.85-7.11,19.83,19.83,0,0,0-12.26-4.18h-86a19.83,19.83,0,0,0-12.26,4.18,21,21,0,0,0-6,7.11"
                        fill="var(--border-header-hover, #86BCCE)" stroke="#666" stroke-miterlimit="10"
                        stroke-width="2"></path>
                    <path
                        d="M88.83,296.34a20.87,20.87,0,0,0,6,7.11,19.77,19.77,0,0,0,12.26,4.18h86a19.77,19.77,0,0,0,12.26-4.18,20.47,20.47,0,0,0,5.85-7.11H88.83"
                        fill="var(--border-header-hover, #86BCCE)" stroke="#666" stroke-miterlimit="10"
                        stroke-width="2"></path>
                </g>
                <g class="ufoLightGroup" stroke="#333" fill="#F73E3E">
                    <circle cx="108.2" cy="284.71" r="2.09">
                        <animate begin="0ms" attributeName="fill-opacity" values="0;0.8;0" dur="3s"
                            repeatCount="indefinite"></animate>
                    </circle>
                    <circle cx="122.13" cy="284.71" r="2.09">
                        <animate begin="200ms" attributeName="fill-opacity" values="0;0.8;0" dur="3s"
                            repeatCount="indefinite"></animate>
                    </circle>
                    <circle cx="136.07" cy="284.71" r="2.09">
                        <animate begin="400ms" attributeName="fill-opacity" values="0;0.8;0" dur="3s"
                            repeatCount="indefinite"></animate>
                    </circle>
                    <circle cx="150" cy="284.71" r="2.09">
                        <animate begin="600ms" attributeName="fill-opacity" values="0;0.8;0" dur="3s"
                            repeatCount="indefinite"></animate>
                    </circle>
                    <circle cx="163.93" cy="284.71" r="2.09">
                        <animate begin="800ms" attributeName="fill-opacity" values="0;0.8;0" dur="3s"
                            repeatCount="indefinite"></animate>
                    </circle>
                    <circle cx="177.87" cy="284.71" r="2.09">
                        <animate begin="1000ms" attributeName="fill-opacity" values="0;0.8;0" dur="3s"
                            repeatCount="indefinite"></animate>
                    </circle>
                    <circle cx="191.8" cy="284.71" r="2.09">
                        <animate begin="1200ms" attributeName="fill-opacity" values="0;0.8;0" dur="3s"
                            repeatCount="indefinite"></animate>
                    </circle>
                </g>
                <animateTransform attributeName="transform" attributeType="XML" type="translate"
                    values="0 -5;0 17.5;0 -5" dur="2s" repeatCount="indefinite"></animateTransform>
            </g>
        </svg>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>