const THEME_KEY = "theme_pc";
import localStorage from '@/utils/localStorage'
export default {
  namespaced: true,
  state: {
    theme: localStorage.getItem(THEME_KEY) || "#256DCB",
    key: THEME_KEY
  },
  getters: {},
  mutations: {
    CHANGE_SETTING: (state, { key, value }) => {
      if (state.hasOwnProperty(key)) {
        state[key] = value;
        if (key === "theme") {
          localStorage.setItem(THEME_KEY, value);
        }
      }
    },
  },
  actions: {
    changeSetting({ commit }, data) {
      commit("CHANGE_SETTING", data);
    },
  },
};
