import axios from "axios";
import config from "@/config";
import localStorage from "./localStorage";
import { Message } from "element-ui";

const instance = axios.create({
  baseURL: config.SERVER_URL + "/api",
  timeout: 6000,
  headers: { "Content-Type": "application/json" },
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (requestConfig) {
    // 在发送请求之前做些什么
    let TOKEN = localStorage.getItem("TOKEN");
    if (TOKEN) requestConfig.headers.TOKEN = TOKEN; // 统一携带请求头
    return requestConfig;
  },
  function (error) {
    Message.error("请求连接失败,请联系管理员");
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data.code == 200) return response.data;
    Message.error(response.msg);
  },
  function (error) {
    Message.error("请求响应失败,请联系管理员");
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;
