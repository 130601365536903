<template>

  <div class="banner">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item, index in bannerArr" :key="index">
          <img class="banner__carousel__img" :src="item.image" @click="handleClick(item)">
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>

</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  props: {
    bannerArr: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
  },
  updated() {
    var mySwiper = new Swiper('.swiper-container', {
      loop: true, // 循环模式选项
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },

      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 25%;

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;

    &:hover {

      .swiper-button-prev,
      .swiper-button-next {
        transform: translateX(0);
      }
    }

    .swiper-wrapper .swiper-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .swiper-button-prev,
    .swiper-button-next {
      transition: .5s;
    }

    .swiper-button-prev {
      transform: translateX(-200%);
    }

    .swiper-button-next {
      transform: translateX(200%);
    }
  }
}

@media (max-width:768px) {
  .banner {
    padding-bottom: 50%;

    .swiper-container .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}
</style>