<template>
    <div class="zhouyi">
        <div class="zhouyi_content">
            <div class="origin color">
                <p>《易经》是中华民族传统思想文化中自然哲学与人文实践的理论根源，是古代汉民族思想、智慧的结晶，被誉为“大道之源”，是古代帝王之学，政治家、军事家、商家的必修之术。</p>
                <p>《易经》含盖万有，纲纪群伦，是中华民族传统文化的杰出代表;广大精微，包罗万象。</p>
                <p>据《史记·周本纪》记载:文王"其囚羑里，盖益易之八卦为六十四卦"。也就是说，当时文王(西伯昌)被崇侯陷害而被殷帝纣囚禁在羑里整七年，在狱中，西伯昌潜心研究易学八卦，通过八卦相叠从而推演出现代《易经》中所记载的乾为天、坤为地、水雷屯、山水蒙等六十四卦。
                </p>
            </div>
            <div class="center">
                <div class="btn" @click="update"><span>每日一卦</span></div>
                <div class="date is-active" v-if="date" @touchstart.prevent="goTouchstart()"
                    @touchend.prevent="goTouchend()">{{
                    setDate }}</div>
                <div class="no" v-if="answer.id">
                    <span>{{ answer.id }}</span>
                    <span class="tips">:</span>
                    <span>{{ answer.gua }}</span>
                    <span>({{ answer.gua_details }})</span>
                </div>
                <div class="divinatory_sign" v-if="answer.remarks">
                    <span v-for="item, index in answer.remarks.split('，')" :key="index">{{ item == '单' ? '阳爻' : '阴爻'
                        }}</span>
                </div>
            </div>
            <div class="answer">{{ answer.gua_explain }}</div>
            <a :href="answer.link">
                <div class="btn" style="margin-top: 20px;"><span>百度百科</span></div>
            </a>

        </div>
        <taiji v-if="taijiIsShow" />
    </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import taiji from './components/taiji.vue'
import { zhouYiApi } from '@/api/zhouyi'
export default {
    title: '每日一卦',
    meta: { name: 'zhouyi', submenuTitle: '每日一挂' },
    components: { taiji },
    data() {
        return {
            answer: {},
            date: '',
            isunrestrict: true,
            taijiIsShow: false,
            timeOutEvent: 0 //记录触摸时长
        }
    },
    computed: {
        setDate() {
            if (!this.date) return '--'
            return dayjs(this.date).format('YYYY-MM-DD HH:mm:ss')
        },
    },
    async mounted() {
        let date = localStorage.getItem('date')
        let data = localStorage.getItem('data')
        this.answer = data ? JSON.parse(data) : {}
        this.date = Number(date) || ''
    },
    methods: {
        async getData() {
            let res = await zhouYiApi()
            this.date = dayjs(new Date()).valueOf()
            return res.data.list[0]
        },
        async update() {
            if (this.isunrestrict ? dayjs(this.date).format('MM-DD') == dayjs(new Date()).format('MM-DD') : false) {
                this.$message({
                    showClose: true,
                    message: '每日一挂,您今天已经算过,请明天再来',
                    type: 'error'
                });
                return
            }
            this.taijiIsShow = true
            let data = await this.getData();

            setTimeout(() => {
                this.answer = data
                localStorage.setItem('date', this.date)
                localStorage.setItem('data', JSON.stringify(this.answer))
                this.taijiIsShow = false
            }, 2000)
        },
        // 解除限制
        unrestrict() {
            if (!this.isunrestrict) {
                this.$message({
                    message: '限制已解除,无需重复操作',
                    type: 'warning'
                });
                return
            }
            this.isunrestrict = false
            this.$message({
                message: '你个老六,解除限制成功',
                type: 'success'
            });
        },
        goTouchstart() {
            let _this = this;
            clearTimeout(_this.timeOutEvent);
            _this.timeOutEvent = setTimeout(function () {
                _this.timeOutEvent = 0;
                //  处理长按事件...
                _this.unrestrict()
            }, 3000);
        },
        //手如果在3000毫秒内就释放，则取消长按事件
        goTouchend() {
            let _this = this;
            clearTimeout(_this.timeOutEvent);
            if (_this.timeOutEvent !== 0) {
                //  处理单击事件
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.zhouyi {
    width: 100%;
    min-height: 100vh;
    position: relative;
    // background: #000;
    background: url(http://8.134.151.130/image/zhouyi_bg.png) no-repeat center top #000;
    background-size: auto 100vh;
    color: #fff;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;

    // &::after {
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     z-index: 1;
    //     width: 100%;
    //     height: 100%;
    //     background: url(http://8.134.151.130/image/zhouyi_bg.png) no-repeat center top #000;
    //     background-size: auto 100vh;
    //     opacity: .6;
    // }

    .zhouyi_content {
        // position: relative;
        // z-index: 2;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .origin {
            flex: none;
            background: rgba(0, 0, 0, .5);
            padding: 10px;
            color: #fff;
            border-radius: 6px;
            box-shadow: -6px 6px 2px rgba(0, 0, 0);
            border: 1px solid rgba(255, 255, 255, .5);
        }

        .center {
            flex: none;
            width: 100%;
            text-align: center;
            margin: 20px 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            .date {
                margin: 10px 0;
            }

            .no {
                margin-bottom: 10px;

                .tips {
                    margin: 0 6px;
                }
            }

            .divinatory_sign {
                span {
                    margin: 6px;
                    color: #ff7300;
                }
            }
        }
    }
}

.btn {
    width: 200px;
    background: linear-gradient(to left, #fff 50%, #000 50%);
    border: 1px solid #fff;
    padding: 6px 0;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;

    span {
        color: #fff;
        mix-blend-mode: difference;

    }
}

.answer {
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 6px;
    padding: 10px;
    white-space: pre-wrap;
    background: rgba(0, 0, 0, .5);
    padding: 10px;
    color: #fff;
    border-radius: 6px;
    box-shadow: -6px 6px 2px rgba(0, 0, 0);
}
</style>