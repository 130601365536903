import CryptoJS from "crypto-js";

let KEY = '@yuwan'

/**
 * 
 * @param {String} name - 储存数据名称
 * @returns 
 */
function getItem(name) {
    if (!name) return '参数有误'
    var tk = localStorage.getItem(name);//把存储的值取出
    if (!tk) return ''
    var bytes = CryptoJS.AES.decrypt(tk, KEY);
    //要解密的值   //解密的秘钥（必须与加密的秘钥一直）
    var originalText = bytes.toString(CryptoJS.enc.Utf8);//解密操作
    return originalText
}

/**
 * 
 * @param {String} name - 储存数据名称
 * @param {String} data - 储存数据
 */

function setItem(name, data) {
    if (!name || !data) return '参数有误'
    let cipherText = CryptoJS.AES.encrypt(data, KEY).toString();
    //要存储的值 //加密的秘钥（解密的时候必须要根据秘钥才能解密） 
    localStorage.setItem(name, cipherText);//本地存储
}

export default {
    getItem,
    setItem
}