<template>
  <div class="album">
    <div class="phone header">
      <img src="@/assets/image/album/album.gif" alt="">
    </div>
    <div class="album_list">
      暂无相册,快去添加吧...
    </div>
    <div class="add color">+</div>
  </div>
</template>

<script>
export default {
  title: "相册",
  meta: { name: "album", submenuTitle: "相册", icon: "el-icon-s-home" },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  box-shadow: 2px 0 10px #ccc;

  img {
    width: 100%;
  }
}

.album_list{
  padding: 20px;
}

.add {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
  border-radius: 50%;
  background: var(--color);
}
</style>