<template>
  <div>
    product
  </div>
</template>

<script>
  export default {
    title: '商品',
    meta: {name: 'product',submenuTitle: '商品',icon: 'el-icon-s-claim'}
  }
</script>

<style lang="sass" scoped>

</style>