import Vue from 'vue'
import Vuex from 'vuex'
import util from '@/utils'

Vue.use(Vuex)

let fileArr = util.auto.fileAutomate(require.context('@/store/modules',false,/.js$/))

let modules = {}

fileArr.forEach(item => {
  modules[item.path.replace(/(.*).js/,'$1')] = item.content
})

// console.log(modules);

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules
})
