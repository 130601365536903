import auto from './auto.js'

let util = auto.fileAutomate(require.context('@/utils',false,/.js$/))

let utils = util.filter(i => i.path != 'index.js');

// console.log(utils);

let exp = {}

utils.forEach(item => {
  exp[item.path.replace((/(.*).js/),'$1')] = item.content
})

export default exp