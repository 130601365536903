<template>
    <div class="taiji">
        <div class="taiji_content">
            <div class="Taichi_outside">
                <div class="Taichi">
                    <div class="middle_round">
                        <div class="small_round"></div>
                    </div>
                    <div class="middle_round">
                        <div class="small_round"></div>
                    </div>
                </div>
                <!-- <div class="outer_layer">
                    <div class="trapezium">
                        <div class="trigram_item sun"></div>
                        <div class="trigram_item line"></div>
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item line"></div>
                        <div class="trigram_item sun"></div>
                    </div>
                    <div class="trapezium">
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item lunar"></div>
                    </div>
                    <div class="trapezium">
                        <div class="trigram_item sun"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item lunar"></div>
                    </div>
                    <div class="trapezium">
                        <div class="trigram_item sun"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item sun"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item sun"></div>
                    </div>
                    <div class="trapezium">
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item sun"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item lunar"></div>
                    </div>
                    <div class="trapezium">
                        <div class="trigram_item sun"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item lunar"></div>
                    </div>
                    <div class="trapezium">
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item sun"></div>
                    </div>
                    <div class="trapezium">
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item lunar"></div>
                        <div class="trigram_item"></div>
                        <div class="trigram_item sun"></div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.taiji {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.taiji_content {
    // background: #fff;
    width: 300px;
    height: 300px;
    position: relative;
}

/*大圆的格式代码*/
.Taichi {
    width: 300px;
    height: 300px;
    border: 5px solid #000;
    border-radius: 150px;
    position: absolute;
    top: 0;
    left: 0;
    // position: relative;
    box-shadow: 0px 5px 15px gray;
    background-image: linear-gradient(to right, white 50%, black 50%, black 75%);
    /*用CSS3渐变可以只写一个圆而不用再写两个半圆*/
}

/*中圆的格式代码*/
.Taichi .middle_round {
    width: 150px;
    height: 150px;
    border-radius: 75px;
    position: absolute;
    left: 75px;
}

.Taichi .middle_round:first-of-type {
    background-color: #fff;
    top: 0px;
}

.Taichi .middle_round:last-of-type {
    background-color: #000;
    top: 150px;
}

/*小圆的格式代码*/
.Taichi .small_round {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: absolute;
    left: 50px;
    top: 50px;
}

.Taichi .middle_round:first-of-type .small_round {
    background-color: #000;
}

.Taichi .middle_round:last-of-type .small_round {
    background-color: #fff;
}

/*太极代码*/
.Taichi {
    animation: myrotate 1s linear infinite;
}

/*动画代码*/
@keyframes myrotate {
    0% {
        transform: rotate(0deg) scale(0.5);
    }

    50% {
        transform: rotate(180deg) scale(0.5);
    }

    100% {
        transform: rotate(360deg) scale(0.5);
    }
}

// 梯形代码
// .outer_layer {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
// }

// .trapezium {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     background: #fff;
//     width: 100px;
//     height: 60px;
//     clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 20% 100%);
//     display: flex;
//     flex-direction: column;

//     .trigram_item {
//         flex: 1;
//     }
// }

// .trapezium .line {
//     height: .5;
// }

// .trapezium .sun {
//     background: #000;
// }

// .trapezium .lunar {
//     background: linear-gradient(to left, #000 0%, #000 45%, #fff 45%, #fff 55%, #000 55%, #000 100%);
// }

// @for $i from 1 to 9 {
//     .trapezium:nth-child(#{$i}) {
//         transform: translate(-50%,-50%) rotate(calc( (360 / 8) * #{$i - 1}deg));
//     }
// }
// .trapezium:nth-child(1){
//     top: -110px;
// }
// .trapezium:nth-child(2){
//     top: -110px;
// }
</style>